<template>
  <div class="auth-form">
    <div v-if="!isNotice" class="auth-form__content">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        :hide-required-asterisk="true"
        @submit.native.prevent="submitForm('form')"
      >
        <el-form-item label="E-mail" prop="email">
          <el-input v-model="form.email" ref="email" type="email" />
        </el-form-item>

        <el-form-item label="Установите новый пароль" prop="pass">
          <el-input v-model="form.pass" type="password" show-password />
        </el-form-item>

        <el-form-item label="Подтвердите новый пароль" prop="checkPass">
          <el-input v-model="form.checkPass" type="password" show-password />
        </el-form-item>

        <el-form-item label="Проверочный код" prop="resetCode">
          <el-input v-model="form.resetCode" type="text" />
        </el-form-item>

        <el-form-item>
          <el-button type="primary el-button--stretch" native-type="submit">
            Восстановить пароль
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="isNotice" class="auth-form__notice">
      {{ message }}
    </div>
    <div class="auth-form__footer">
      <router-link
        v-if="!isNotice"
        class="login-page__link"
        :to="{ name: 'Login' }"
      >
        Отмена
      </router-link>
      <router-link
        v-if="isNotice"
        class="login-page__link"
        :to="{ name: 'Login' }"
      >
        Войти
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResetPassword',
  data() {
    const checkEmail = (rule, value, callback) => {
      if (this.authErrors && this.authErrors.email) {
        callback(new Error(`${this.authErrors.email}`))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Введите новый пароль'))
      } if (String(value).length < 6) {
        callback(new Error('Пароль должен быть не короче 6 символов'))
      } else if (/[а-я]|[А-Я]/gi.test(value)) {
        callback(new Error('Пароль не должен содержать кириллицу'))
      } else {
        if (this.form.checkPass !== '') {
          this.$refs.form.validateField('checkPass')
        }
        callback()
      }
    }
    const validatePassword2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Введите пароль еще раз'))
      } else if (value !== this.form.pass) {
        callback(new Error('Пароли не совпадают'))
      } else {
        callback()
      }
    }
    const checkPassword = (rule, value, callback) => {
      if (this.authErrors && this.authErrors.password) {
        callback(new Error(`${this.authErrors.password}`))
      } else {
        callback()
      }
    }
    const checkCode = (rule, value, callback) => {
      if (this.authErrors && this.authErrors.password_reset_code) {
        callback(new Error(`${this.authErrors.password_reset_code}`))
      } else {
        callback()
      }
    }
    return {
      form: {
        email: '',
        pass: '',
        checkPass: '',
        resetCode: '',
      },
      rules: {
        email: [
          {
            required: true,
            message: 'Введите e-mail адрес',
            trigger: ['blur', 'change'],
          },
          {
            type: 'email',
            message: 'Выбранное значение для e-mail адреса некорректно.',
            trigger: 'blur',
          },
          { validator: checkEmail, trigger: 'blur' },
        ],
        pass: [
          { validator: validatePassword, trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' },
        ],
        checkPass: [
          { validator: validatePassword2, trigger: ['blur', 'change'] },
        ],
        resetCode: [
          { required: true, message: 'Введите код', trigger: 'blur' },
          { validator: checkCode, trigger: ['blur', 'change'] },
        ],
      },
      isNotice: false,
      message: '',
    }
  },
  mounted() {
    this.$refs.email.focus()
  },
  computed: {
    authErrors() {
      return this.$store.state.Auth.errors
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.$store.commit('Auth/setData', { label: 'errors', data: null })
      },
    },
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.resetPassword()
        } else {
          return false
        }
      })
    },
    resetPassword() {
      const data = {
        email: this.form.email,
        password: this.form.pass,
        password_confirmation: this.form.checkPass,
        password_reset_code: this.form.resetCode,
      }
      this.$store
        .dispatch('Auth/resetPassword', data)
        .then(({ data }) => {
          this.isNotice = true
          this.message = data.data.message
          // this.$router.push({ name: 'Login' })
        })
        .catch(err => {
          console.error(err.response)
          this.$refs['form'].validate(() => {})
        })
    },
  },
}
</script>
