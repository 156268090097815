var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-form"
  }, [!_vm.isNotice ? _c('div', {
    staticClass: "auth-form__content"
  }, [_c('el-form', {
    ref: "form",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules,
      "hide-required-asterisk": true
    },
    nativeOn: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitForm('form');
      }
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "E-mail",
      "prop": "email"
    }
  }, [_c('el-input', {
    ref: "email",
    attrs: {
      "type": "email"
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Установите новый пароль",
      "prop": "pass"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "password",
      "show-password": ""
    },
    model: {
      value: _vm.form.pass,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pass", $$v);
      },
      expression: "form.pass"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Подтвердите новый пароль",
      "prop": "checkPass"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "password",
      "show-password": ""
    },
    model: {
      value: _vm.form.checkPass,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "checkPass", $$v);
      },
      expression: "form.checkPass"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Проверочный код",
      "prop": "resetCode"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.resetCode,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "resetCode", $$v);
      },
      expression: "form.resetCode"
    }
  })], 1), _c('el-form-item', [_c('el-button', {
    attrs: {
      "type": "primary el-button--stretch",
      "native-type": "submit"
    }
  }, [_vm._v(" Восстановить пароль ")])], 1)], 1)], 1) : _vm._e(), _vm.isNotice ? _c('div', {
    staticClass: "auth-form__notice"
  }, [_vm._v(" " + _vm._s(_vm.message) + " ")]) : _vm._e(), _c('div', {
    staticClass: "auth-form__footer"
  }, [!_vm.isNotice ? _c('router-link', {
    staticClass: "login-page__link",
    attrs: {
      "to": {
        name: 'Login'
      }
    }
  }, [_vm._v(" Отмена ")]) : _vm._e(), _vm.isNotice ? _c('router-link', {
    staticClass: "login-page__link",
    attrs: {
      "to": {
        name: 'Login'
      }
    }
  }, [_vm._v(" Войти ")]) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }